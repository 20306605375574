<template>
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container">
                    <div class="modal-header">
                        <a href="#" class="close"  @click="$emit('close')"></a>
                    </div><!-- /.modal-header -->

                    <div class="modal-body">
                        <form class="form" name="form" @submit.prevent="submit" method="post" novalidate>
                            <div class="form__row">
                                <div class="form__column">
                                    <!-- FirstName -->
                                    <div class="form__input_container">
                                        <input
                                            class="form__input"
                                            type="text"
                                            id="firstName"
                                            name="firstName"
                                            data-vv-as="Ім'я"
                                            v-model="form.userFirstName"
                                            v-validate="'required|alpha_dash_spaces'"
                                            :class="{'is-valid': true, 'is-invalid': errors.has('firstName') }"
                                            required
                                        >
                                        <label class="form__label" for="firstName">Ім'я</label>

                                        <div v-show="errors.has('firstName')" :class="{'invalid-feedback': true}">{{ errors.first('firstName') }}</div>
                                    </div><!-- /.form__container -->
                                </div><!-- /.form__column -->

                                <div class="form__column">
                                    <!-- LastName -->
                                    <div class="form__container">
                                        <input
                                            class="form__input"
                                            type="text"
                                            id="lastName"
                                            name="lastName"
                                            data-vv-as="Прізвище"
                                            v-model="form.userLastName"
                                            v-validate="'required|alpha_dash_spaces'"
                                            :class="{'is-valid': true, 'is-invalid': errors.has('lastName') }"
                                            required
                                        >
                                        <label class="form__label" for="lastName">Прізвище</label>

                                        <div v-show="errors.has('lastName')" :class="{'invalid-feedback': true}">{{ errors.first('lastName') }}</div>
                                    </div><!-- /.form__container -->
                                </div><!-- /.form__column -->
                            </div><!-- /.form__row -->

                            <div class="form__row">
                                <div class="form__column">
                                    <!-- email -->
                                    <div class="form__input_container">
                                        <input
                                            class="form__input"
                                            type="email"
                                            id="email"
                                            name="email"
                                            data-vv-as="Email"
                                            v-model="form.userEmail"
                                            v-validate="'required|email'"
                                            :class="{'is-valid': true, 'is-invalid': errors.has('email') }"
                                            required
                                        >
                                        <label class="form__label" for="email">Email</label>

                                        <div v-show="errors.has('email')" class="invalid-feedback">{{ errors.first('email') }}</div>
                                    </div><!-- /.form__container -->
                                </div><!-- /.form__column -->

                                <div class="form__column">
                                    <!-- phone/skype -->
                                    <div class="form__container">
                                        <input
                                            class="form__input"
                                            type="text"
                                            id="phoneOrSkype"
                                            name="phoneOrSkype"
                                            data-vv-as="Телефон/Скайп"
                                            v-model="form.userPhoneOrSkype"
                                            v-validate="'required'"
                                            :class="{'is-valid': true, 'is-invalid': errors.has('userPhoneOrSkype') }"
                                            required
                                        >
                                        <label class="form__label" for="phoneOrSkype">Телефон/Скайп</label>

                                        <div v-show="errors.has('phoneOrSkype')" :class="{'invalid-feedback': true}">{{ errors.first('phoneOrSkype') }}</div>
                                    </div><!-- /.form__container -->
                                </div><!-- /.form__column -->
                            </div><!-- /.form__row -->

                            <!-- message -->
                            <div class="form__container">
                                <label class="form__label form__label--textarea" for="message">Повідомлення</label>
                                <textarea
                                    class="form__textarea"
                                    id="message"
                                    name="message"
                                    data-vv-as="Повідомлення"
                                    v-model="form.userMessage"
                                    v-validate="'required'"
                                    :class="{'is-valid': true, 'is-invalid': errors.has('message') }"
                                ></textarea>

                                <div v-show="errors.has('message')" class="invalid-feedback">{{ errors.first('message') }}</div>
                            </div><!-- /.form__container -->

                            <input class="form__submit" type="submit" value="Надіслати запит">
                        </form>
                    </div><!-- /.modal-body -->
                </div><!-- /.modal-container -->
            </div><!-- /.modal-wrapper -->
        </div><!-- /.modal-mask -->
    </transition>
</template>

<script>
    import axios from 'axios';

    export default {
        name: 'booking-dialog',
        validator: null,
        data: () => ({
            form: {
                userFirstName: '',
                userLastName: '',
                userEmail: '',
                userPhoneOrSkype: '',
                userMessage: ''
            }
        }),
        methods: {
            submit() {
                this.$validator.validateAll().then((result) => {
                    if (result) {
                        let data = {
                            name: this.form.userFirstName +' '+ this.form.userLastName,
                            email: this.form.userEmail,
                            contact: this.form.userPhoneOrSkype,
                            message: this.form.userMessage
                        };

                        axios.post('/book', data)
                            .then(response => {
                                this.clearForm();

                                this.$toastr.s(response.data.message);
                                this.$emit('close');
                            });
                    }
                });
            },
            clearForm() {
                this.form = {};

                this.$validator.reset();
            }
        }
    };
</script>

<style>
    .modal-mask {
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        display: table;
        transition: opacity .3s ease;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }

    .modal-container {
        width: 640px;
        margin: 0 auto;
        padding: 50px 40px;
        background-color: #fff;
        border-radius: 2px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
        transition: all .3s ease;
    }

    @media (max-width: 767px) {
        .modal-container {
            width: 420px;
            padding: 30px 20px;
        }
    }

    @media (max-width: 425px) {
        .modal-container {
            width: 300px;
        }
    }

    @media (max-width: 340px) {
        .modal-container {
            width: 260px;
        }
    }

    .modal-header {
        position: relative;
        margin-top: 0;
        color: #42b983;
    }

    .modal-body {
        margin: 20px 0;
    }

    .modal-default-button {
        float: right;
    }

    /*
     * The following styles are auto-applied to elements with
     * transition="modal" when their visibility is toggled
     * by Vue.js.
     *
     * You can easily play with the modal transition by editing
     * these styles.
     */

    .modal-enter {
        opacity: 0;
    }

    .modal-leave-active {
        opacity: 0;
    }

    .modal-enter .modal-container,
    .modal-leave-active .modal-container {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }

    .close {
        position: absolute;
        right: -10px;
        top: -30px;
        width: 15px;
        height: 15px;
        opacity: .3;
    }
    .close:hover {
        opacity: 1;
    }
    .close:before, .close:after {
        position: absolute;
        left: 15px;
        content: ' ';
        height: 15px;
        width: 2px;
        background-color: #333;
    }
    .close:before {
        transform: rotate(45deg);
    }
    .close:after {
        transform: rotate(-45deg);
    }

    @media (max-width: 767px) {
        .close {
            right: 5px;
            top: -10px;
        }
    }
</style>
