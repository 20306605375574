import Vue from 'vue';
import VeeValidate, { Validator } from 'vee-validate';
import validationMessages from 'vee-validate/dist/locale/uk';
import Toastr from 'vue-toastr';
import ContactForm from './components/ContactFormComponent.vue';
import BookingDialog from './components/BookingDialogComponent';

Vue.use(VeeValidate, {
    locale: 'uk',
    dictionary:  {
        uk: validationMessages
    }
});
Vue.use(Toastr);

Validator.extend('alpha_dash_spaces', {
    getMessage: field => 'Поле ' + field + ' може містити лише символи',
    validate: value => !!value.match(/^[-\sa-zA-Zа-яА-ЯіїІЇ-]+$/u)
});

new Vue({
    el: '#app',
    components: {
        contactForm: ContactForm,
        bookingDialog: BookingDialog
    },
    data: {
        showModal: false
    }
});

/**
*
* Toggle class function
*
* @param element
* @param className
*/
function toggleClass(element, className) {
    let el = document.querySelector(element);

    if (el) {
        el.classList.toggle(className);
    }
}

// Mobile menu toggle
document.querySelector('.hamburger').addEventListener('click', () => {
    toggleClass('.home-nav', 'show');
    toggleClass('.nav', 'show');
    toggleClass('.hamburger', 'is-active');
    toggleClass('.mobile-title', 'show');
});