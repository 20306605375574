<script>
    import axios from 'axios';

    export default {
        name: 'contact-form',
        validator: null,
        data: () => ({
            form: {
                userName: '',
                userEmail: '',
                userMessage: ''
            }
        }),
        methods: {
            submit() {
                this.$validator.validateAll().then((result) => {
                    if (result) {
                        let data = {
                            name: this.form.userName,
                            email: this.form.userEmail,
                            message: this.form.userMessage
                        };

                        axios.post('/contact', data)
                            .then(response => {
                                this.clearForm();

                                this.$toastr.s(response.data.message);
                            });
                    }
                });
            },
            clearForm() {
                this.form = {};

                this.$validator.reset();
            }
        }
    };
</script>
